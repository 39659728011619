<template>
  <Auth />
  <div class="py-4 container-fluid">
    <OverviewBoxOrder
      :clickable="clickable"
      @viewdetailpackage="handleviewdetailpackage"
    />
    <div class="mt-4 row">
      <div v-if="showPackageDetail" class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <div class="row">
              <div class="col-md-6">
                <h5 class="mb-0">
                  {{ dataPackageSelected.merchant_order_number }}
                </h5>
                <p class="mb-0 text-sm">
                  {{ dataPackageSelected.to_name }} |
                  {{ dataPackageSelected.to_phone_number }}
                </p>
              </div>
              <div class="col-md-6 text-end">
                <button
                  type="button"
                  class="btn btn-primary"
                  @click.prevent="backToPackageHistory"
                >
                  Kembali ke Riwayat Paket
                </button>
              </div>
            </div>
          </div>
          <div class="card-body" style="border-top: 1px solid #eeeeee">
            <ViewPackageDetail
              :data-package="dataPackageSelected"
              @newstatuscallback="handleNewStatusCallback"
            />
            <div class="mt-3 text-end">
              <button
                type="button"
                class="btn btn-primary"
                @click.prevent="backToPackageHistory"
              >
                Kembali ke Riwayat Paket
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isLoadingTable" class="col-12">
        <div class="card">
          <div class="card-header">
            <el-skeleton :rows="4" animated />
          </div>
        </div>
      </div>
      <div v-if="showTable" class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <h5 class="mb-0">Riwayat Paket</h5>
            <p class="mb-0 text-sm">
              Daftar riwayat paket yang dibuat selama ini.
            </p>
          </div>
          <div class="card-body" style="border-top: 1px solid #eeeeee">
            <div class="row">
              <div class="col-md-6"></div>
              <div class="col-md-6 text-end">
                <div class="btn-group" role="group">
                  <button
                    type="button"
                    class="btn btn-sm btn-success"
                    @click.prevent="GoToAddPackage"
                  >
                    + Tambah Paket
                  </button>
                  <div class="dropdown" style="margin-left: 0px">
                    <button
                      id="dropdownMenuButton1"
                      style="border-radius: 0px 5px 5px 0px"
                      class="btn btn-sm btn-dark dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      More
                    </button>
                    <ul
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li>
                        <a class="dropdown-item" @click="refreshTable"
                          >Refresh Tabel</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
                &nbsp;
              </div>
            </div>
            <div>
              <div
                class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <el-select
                  v-model="pagination.perPage"
                  class="select-primary pagination-select"
                  placeholder="Per page"
                >
                  <el-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    class="select-primary"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>

                <div>
                  <el-input
                    v-model="searchQuery"
                    type="search"
                    class="mb-3"
                    clearable
                    prefix-icon="el-icon-search"
                    style="width: 200px"
                    placeholder="Search records"
                    aria-controls="datatables"
                    @input="handleInput"
                  >
                  </el-input>
                </div>
              </div>
              <div class="table-responsive">
                <el-table
                  :data="queriedData"
                  row-key="id"
                  header-row-class-name="thead-light"
                  @selection-change="selectionChange"
                >
                  <template #empty>
                    <div class="flex items-center justify-center h-100%">
                      <el-empty />
                    </div>
                  </template>
                  <el-table-column
                    v-for="column in tableColumns"
                    :key="column.label"
                    v-bind="column"
                  >
                  </el-table-column>
                  <el-table-column
                    min-width="100px"
                    align="left"
                    label="Tracking ID"
                  >
                    <template #default="props">
                      <a
                        href="#"
                        @click.prevent="
                          handleViewPackageDetail(props.$index, props.row)
                        "
                        >{{ props.row.merchant_order_number }}<br />
                        <small class="text-secondary">
                          {{ props.row.email }}<br />
                          {{ props.row.display_created_at }}<br /> </small
                      ></a>
                    </template>
                  </el-table-column>
                  <el-table-column
                    min-width="100px"
                    align="left"
                    label="Pengirim"
                  >
                    <template #default="props">
                      <a
                        href="#"
                        @click.prevent="
                          handleViewPackageDetail(props.$index, props.row)
                        "
                        >{{ props.row.from_name }}
                        <i
                          v-if="props.row.official_dropship_store_id"
                          class="fas fa-star text-primary"
                          style="font-size: 12px"
                        ></i>

                        <br /><small class="text-secondary"
                          >{{ props.row.from_phone_number }}<br />{{
                            props.row.from_address_1
                          }}<br /><span style="color: #bbbbbb">{{
                            props.row.origin
                          }}</span></small
                        ></a
                      >
                    </template>
                  </el-table-column>
                  <el-table-column
                    min-width="100px"
                    align="left"
                    label="Penerima"
                  >
                    <template #default="props">
                      <a
                        href="#"
                        @click.prevent="
                          handleViewPackageDetail(props.$index, props.row)
                        "
                        >{{ props.row.to_name }}<br />
                        <small class="text-secondary"
                          >{{ props.row.to_phone_number }}<br />
                          {{ props.row.to_address_1 }}<br /><span
                            style="color: #bbbbbb"
                            >{{ props.row.destination }}</span
                          >
                        </small></a
                      >
                    </template>
                  </el-table-column>
                  <el-table-column min-width="50px" align="left" label="COD">
                    <template #default="props">
                      <a
                        href="#"
                        @click.prevent="
                          handleViewPackageDetail(props.$index, props.row)
                        "
                      >
                        <span v-if="props.row.display_cod != ''">
                          {{ props.row.display_cod }}
                        </span>
                        <span v-else
                          ><small
                            ><i class="text-secondary">non cod</i></small
                          ></span
                        >
                      </a>
                    </template>
                  </el-table-column>
                  <el-table-column min-width="50px" align="left" label="Status">
                    <template #default="props">
                      <TrackParcelStatus
                        :status="props.row.status"
                        :message="props.row.error_message"
                      />
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <div v-if="showSelectedAWBButton" class="mt-2">
                <PrintAirWayBillSelectedButton :data="selectedDataRows" />
              </div>

              <br />
              <div
                class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <div class="">
                  <p class="card-category">
                    Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                  </p>
                </div>
                <base-pagination
                  v-model="pagination.currentPage"
                  class="pagination-no-border"
                  :per-page="pagination.perPage"
                  :total="total"
                >
                </base-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Auth from "@/components/auth/Auth";
import {
  ElTable,
  ElTableColumn,
  ElEmpty,
  ElSelect,
  ElOption,
  ElInput,
  ElLoading,
  ElSkeleton,
} from "element-plus";
import BasePagination from "@/components/BasePagination";
import ViewPackageDetail from "@/components/ViewPackageDetail.vue";
import TrackParcelStatus from "@/components/TrackParcelStatus.vue";
import OverviewBoxOrder from "@/components/SendPackages/OverviewBoxOrder.vue";
import PrintAirWayBillSelectedButton from "@/components/PrintAirWayBillSelectedButton.vue";

export default {
  name: "HistoryPackages",
  components: {
    BasePagination,
    Auth,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
    ElEmpty,
    ElSkeleton,
    ViewPackageDetail,
    TrackParcelStatus,
    OverviewBoxOrder,
    PrintAirWayBillSelectedButton,
  },
  data() {
    return {
      modals: {
        classic: false,
        classic2: false,
      },
      urlread: "/api/v2/send_packages/read",
      dataTable: [],
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: [
        "merchant_order_number",
        "from_name",
        "from_address_1",
        "to_name",
        "to_phone_number",
        "to_address_1",
        "destination",
        "status",
        "display_created_at",
        "cash_on_delivery",
        "display_cod"
      ],
      tableColumns: [
        {
          type: "selection",
        },
      ],
      rawTableData: [],
      datas: [],
      tableData: [],
      fuseSearch: null,
      searchedData: [],
      isLoading: false,
      isLoadingTable: false,
      loadingTable: true,
      showTable: false,
      showPackageDetail: false,
      dataPackageSelected: [],
      indexActive: "",
      clickable: true,

      showSelectedAWBButton: false,
      selectedDataRows: [],
    };
  },
  computed: {
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      return this.tableData;
      /*if (!this.searchQuery) {
        return this.pagedData;
      }
      return this.searchAction.slice(this.from, this.to);*/
    },
    searchAction() {
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          if (row[key] !== null) {
            let rowValue = row[key].toString().toLowerCase();
            if (
              rowValue.includes &&
              rowValue.includes(this.searchQuery.toLowerCase())
            ) {
              isIncluded = true;
            }
          }
        }
        return isIncluded;
      });

      return result;
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.rawTableData.total;
      /*return this.searchAction.length > 0
        ? this.searchAction.length
        : this.tableData.length;*/
    },
  },
  watch: {
    pagination: {
      handler() {
        this.getTables();
      },
      deep: true
    }
  },
  created() {
    this.getTables();
  },
  methods: {
    loadingService() {
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.1)",
      });

      return loading;
    },

    async getTables() {
      try {
        this.loadingService();
        this.isLoadingTable = true;
        this.showTable = false;
        this.showPackageDetail = false;

        let paramURL = '?page=+'+this.pagination.currentPage+'&itemsPerPage='+this.pagination.perPage;
        if(this.searchQuery.length > 0) {
          paramURL = '?page=+'+this.pagination.currentPage+'&itemsPerPage='+this.pagination.perPage+'&search='+this.searchQuery;
        }        
        const response = await axios.get(this.urlread+paramURL, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.rawTableData = response.data.data;
        this.datas = response.data.data.data;
        this.tableData = this.datas;
        this.isLoadingTable = false;
        this.showTable = true;
        this.loadingService().close();
      } catch (error) {
        console.log(error);
        this.isLoadingTable = false;
        this.showTable = true;
        this.errorRedirect500(error);
      }
    },
    handleInput() {
      // Hapus timeout sebelumnya jika ada
      if (this.debounceTimer) {
        clearTimeout(this.debounceTimer);
      }
      
      // Setelah pengguna berhenti mengetik, akan menunggu 500 ms sebelum memicu pencarian
      this.debounceTimer = setTimeout(() => {
        this.searchDataTable();
      }, 500);
    },
    searchDataTable() {
      this.getTables();
    }, 
    selectionChange(selectedRows) {
      let data = [];
      let countSelectedRow = selectedRows.length;
      if (countSelectedRow == 0) {
        this.showSelectedAWBButton = false;
        data = [];
      } else if (countSelectedRow > 0) {
        this.showSelectedAWBButton = true;
        for (let x in selectedRows) {
          data.push(selectedRows[x].merchant_order_number);
        }
      }
      this.selectedDataRows = data;
      //console.log(this.selectedDataRows);
    },

    refreshTable() {
      this.getTables();
    },

    GoToAddPackage() {
      this.$router.push({ path: "/orders/send_packages" });
    },

    handleViewPackageDetail(index, row) {
      this.dataPackageSelected = row;
      this.isLoadingTable = false;
      this.showTable = false;
      this.showPackageDetail = true;
      this.indexActive = index;
    },

    backToPackageHistory() {
      this.isLoadingTable = false;
      this.showTable = true;
      this.showPackageDetail = false;
      this.indexActive = "";
    },
    handleNewStatusCallback(values) {
      this.datas[this.indexActive].status = values;
      this.tableData[this.indexActive].status = values;
    },
    handleviewdetailpackage(values) {
      this.showPackageDetail = false;
      this.showTable = false;
      this.isLoadingTable = true;
      setTimeout(() => {
        this.datas = values;
        this.tableData = this.datas;
        this.isLoadingTable = false;
        this.showTable = true;
      }, 1000);
    },
    errorRedirect500(error) {
      if (error.response.status == 500) {
        setTimeout(() => {
          this.$router.push({ path: "/authentication/error/error500" });
        }, 2000);
      }
    },
  },
};
</script>
